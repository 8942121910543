import standing_pose_pill from '../../assets/standing-pose-pill.png';
import React, { useState, useEffect } from 'react';
import Navbar from '../Dashboardd/Navbar.jsx';
import Header2 from '../Dashboardd/header2.js';
import CustomCursor from "../../components/CustomCursor.js";
import video from '../../assets/images/dapp.mp4';
import portal from '../../assets/boostportal.png';
import './style.css'
import footerVideo from "../../assets/new_image/footer-video-navboost.mp4";
import PHWallet from "../../components/walletBuy.js";
function Fmooners() {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1440);


    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 1440);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const navbarStyle = {
        display: isSmallScreen ? 'none' : 'flex',
        zIndex: 5,
    };

    const header2Style = {
        display: isSmallScreen ? 'block' : 'none',
    };
    return (
        <>
            <CustomCursor />
            <video className="dashboard-video" src={video} playsInline autoPlay muted loop></video>
            <div className="dashboard-main-box">
                <Navbar style={navbarStyle} />
                <Header2 style={header2Style} />
            </div>
            <div className='sidebar-navbar-rest-area'>
                <div className="navboost-main">
                    <img className='fmoonersa' src={standing_pose_pill} alt="" />

                    {/* <div className='mid-section-navboost'>
                        <div>
                            <h4>MARKETING</h4>
                            <br />
                            <br className='d-sm-block d-none'/>
                            <div className="red-box">
                                <ul>
                                    <li>Lorem ipsum dolor sit amet.</li>
                                    <li>Lorem ipsum dolor sit amet.</li>
                                </ul>
                            </div>
                            <br />
                            <br className='d-sm-block d-none'/>
                            <h4>GRAPHICS</h4>
                        </div>
                        <div>
                            <div className='videobox' style={{ backgroundImage: `url(${portal})` }}>
                            
                            </div>
                            <div className="five-circle">
                                <div>1</div>
                                <div>1</div>
                                <div>1</div>
                                <div>1</div>
                                <div>1</div>
                            </div>
                        </div>
                        <div>
                            <h4>UTILITY</h4>
                            <br />
                            <br className='d-sm-block d-none'/>
                            <div className="red-box">
                                <ul>
                                    <li>Lorem ipsum dolor sit amet.</li>
                                    <li>Lorem ipsum dolor sit amet.</li>
                                </ul>
                            </div>
                            <br />
                            <br className='d-sm-block d-none'/>
                            <h4>EXCHANGE</h4>
                        </div>
                    </div> */}


                </div>
            </div>
        </>
    )
}

export default Fmooners
